import "core-js/modules/es.array.push.js";
import { active_apply_page, sendBazeNotice } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        active_name: '',
        apply_state: 1,
        active_type: 2,
        current: 1,
        total: 0
      },
      exportIds: '',
      checkMuneSer: [],
      fileUrl: '../../static/file/course.xlsx'
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.current = 1;
      this.pageInfo.pageSize = 10;
      this.getList();
    },
    getList() {
      active_apply_page(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/base/activeApply/activeApplyInfo",
        query: {
          id: id
        }
      });
    },
    showNotice(id) {
      sendBazeNotice({
        school_active_id: id
      }).then(res => {
        this.$root.msgResult(res, '');
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};